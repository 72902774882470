<template>
  <p class="text-2xl mb-1.5 text-center">
    Total Rewards Calculator
  </p>

  <p v-if="jobRoles.length !== 0">Please select the role and level:</p>

  <div class="grid grid-cols-2 gap-4 lg:grid-cols-2" v-if="jobRoles.length !== 0">
    <div>
      <Multiselect
          v-model="currentRole"
          :options="jobRoles"
          :searchable=true
          label="name"
          placeholder="Select a role"
          valueProp="name"
      />
    </div>

    <div v-if="currentRole">
      <Multiselect
          v-model="currentLevel"
          :options="currentRoleLevels"
          :searchable=true
          label="name"
          placeholder="Select a level"
          valueProp="name"
      />
    </div>
  </div>

  <small class="handbook-link-notifier text-gray-500" v-if="currentLevel && currentRoleDefinition.grade &&!isApplicant">
    The grade for {{currentLevel}} is {{currentRoleDefinition.grade}}.
    Read more about job grades in <a class="no-underline hover:underline text-orange-600" href="https://confluence.camunda.com/pages/viewpage.action?pageId=145590378#id-1.CompensationRanges(WIP)-JobGradingTables">our handbook</a>.
  </small>

  <div v-if="currentRole && currentLevel && countries.length !== 0">
    <p class="mt-4">Please select your country of residence:</p>
    <div class="grid grid-cols-2 gap-4 lg:grid-cols-2" v-if="countries.length !== 0">
      <div>
        <Multiselect
            v-model="currentCountry"
            :options="countries"
            :searchable=true
            label="name"
            placeholder="Select your country of residence"
            valueProp="name"
        />
      </div>
    </div>
  </div>
  <small class="handbook-link-notifier text-gray-500" v-if="currentCountry && currentCountryDetails && !isApplicant">
    {{currentCountry}} is in {{currentCountryDetails.zone}}. Read more about geo zones in <a class="no-underline hover:underline text-orange-600" href="https://confluence.camunda.com/pages/viewpage.action?pageId=145590378#id-1.CompensationRanges(WIP)-GeoFactor">our handbook</a>.
  </small>

  <div class="-mx-1 mt-4 p-4 tr-package-summary" v-if="showSalaryComponent">
    <div>
        The total rewards package for the {{currentLevel}} role in {{currentCountry}} includes:
    </div>

    <div class="ml-2">
      <ul>
        <li>
          <a class="no-underline hover:underline text-orange-600" href="#salary-component">Total Cash <small>*</small></a>
        </li>
        <li><a class="no-underline hover:underline text-orange-600" href="#equity-component">Virtual Stock Options</a></li>
        <li><a class="no-underline hover:underline text-orange-600" href="https://confluence.camunda.com/pages/viewpage.action?pageId=110235989">Global Benefits</a></li>
        <li v-if="currentCountryDetails.benefits_url">
          <a class="no-underline hover:underline text-orange-600" :href=currentCountryDetails.benefits_url>Country Specific Benefits</a>
        </li>
      </ul>

      <div class="flex mt-2">
        <span @click="copyToClipboard" v-tippy="'Click to copy'" class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
          Share
        </span>
        <input v-model="shareLink"
               type="text"
               v-on:focus="$event.target.select()"
               readonly
               class="rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>

      <div class="mt-2">
        <small>* Total Cash refers to base salary and 100% attainment of variable target, where applicable.</small>
      </div>
    </div>

  </div>

  <div id="salary-component" class="mt-4" v-if="showSalaryComponent">
    <salary
        :country="currentCountryDetails"
        :local-currency="currentLocalCurrency"
        :benchmarks="benchmarks"
        :role="currentRoleDefinition"
        :sales-benchmarks="salesBenchmarks"
        :is-applicant="isApplicant"
    />
  </div>

  <div id="equity-component" class="mt-4" v-if="valuations && timeframes">
    <equity
        :vsop-from-role="vsopAmount"
        :valuations="valuations"
        :timeframes="timeframes"
        :salary-is-shown="showSalaryComponent"
        :current-level="currentLevel"
    />
  </div>
</template>

<script>

import Equity from './Equity.vue'
import Salary from './Salary.vue'
import Multiselect from '@vueform/multiselect'

export default {
  name: "TotalRewards",
  components: {
    Equity,
    Salary,
    Multiselect
  },
  data() {
    return {
      sourceData: {},
      jobRoles: [],
      currentRole: null,
      currentLevel: null,
      valuations: null,
      timeframes: null,
      jobGrades: null,
      countries: [],
      currentCountry: null,
      currencies: [],
      benchmarks: [],
      salesBenchmarks: []
    }
  },
  mounted () {
    this.getSourceData();
  },
  computed: {
    isApplicant() {
      if (this.jobRoles.length > 1) {
        return false
      }
      return true
    },
    currentJobFamilyDefinition() {
      if (this.currentRole === null || this.currentRole === "" ) {
        return null
      }

      return this.jobRoles.find(jobRole => {
        return jobRole.job_role.name === this.currentRole
      })
    },
    currentRoleLevels() {
      if (this.currentJobFamilyDefinition === null) {
        return null
      }
      return this.currentJobFamilyDefinition.levels
    },
    currentRoleDefinition() {
      if (!this.currentJobFamilyDefinition || !this.currentLevel) {
        return null
      }

      return this.currentRoleLevels.find(roleLevel => {
        return roleLevel.name == this.currentLevel
      })
    },
    vsopAmount() {
      if (!this.currentRoleDefinition) {
        return null
      }

      return this.currentRoleDefinition.vsop_amount
    },
    currentCountryDetails() {
      return this.countries.find(country => { return country.name == this.currentCountry })
    },
    currentLocalCurrency() {
      if (!this.currentCountryDetails) {
        return null
      }

      if (this.currentCountryDetails.currency) {
        return this.currencies.find(c => { return c.code == this.currentCountryDetails.currency})
      }

      return null
    },
    showSalaryComponent() {
      if (this.currentCountryDetails && this.currentRoleDefinition) {
        return true
      }
      return false
    },
    shareLink() {
      if (!this.currentRole || !this.currentCountry) {
        return null
      }

      const url = new URL(window.location.href);

      url.searchParams.set("role", this.currentRole)
      url.searchParams.set("level", this.currentLevel)
      url.searchParams.set("country", this.currentCountry)

      return url.toString();
    }
  },
  methods: {
    setData(data) {
      this.sourceData = data
      this.jobRoles = data.job_roles
      this.valuations = data.valuations
      this.timeframes = data.timeframes
      this.jobGrades = data.job_grades
      this.countries = data.countries
      this.currencies = data.currencies
      this.benchmarks = data.benchmarks
      this.salesBenchmarks = data.sales_benchmarks

      this.$nextTick(() => {
        this.setParamsFromUrl(window.location.href);
      })

      this.$nextTick(() => {
        if (this.jobRoles.length == 1) {
          this.currentRole = this.jobRoles[0].job_role.name
        }
      })
    },
    setCurrentRoleLevel() {
      if (this.currentRoleLevels.length > 0) {
        this.currentLevel = this.currentRoleLevels[0].name
      }
      else {
        // this.currentLevel = null
      }
    },
    async getSourceData() {
      await fetch('/total_rewards/data.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'same-origin'
      })
      .then(response => response.json())
      .then(data => {
        this.setData(data)
      })
    },
    setParamsFromUrl(url){
      const currentParams = (new URL(url)).searchParams;
      const roleParam = currentParams.get('role')
      const levelParam = currentParams.get('level')
      const countryParam = currentParams.get('country')

      this.currentCountry = countryParam
      this.currentRole = roleParam
      this.currentLevel = levelParam
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.shareLink);
    },
  },
  watch: {
    currentRole(newRole, oldRole) {
      if (newRole && newRole != oldRole && oldRole !== null) {
        this.setCurrentRoleLevel()
      }
    }
  }
}
</script>

<style scoped>
.tr-package-summary {
  border: 1px solid #fb923c;
}

handbook-link-notifier {
    padding-left: 0.75em
}
</style>

