<template>
  <p class="text-xl mb-3">Virtual Stock Options</p>

  <div class="equity-box p-3" v-if="salaryIsShown && vsopFromRole">
    For the {{currentLevel}} role, we grant {{vsopFromRole}} virtual stock options.
  </div>

  <p class="text-l mt-2">Estimated VSOP Gain</p>


  <div class="grid grid-cols-3 gap-4 lg:grid-cols-3 mt-2">
    <div class="flex">
      <span class="items-center p-3 pt-2 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
        VSOP Shares
      </span>
      <input v-model="optionsEntered" type="text" class="rounded-none rounded-r-lg p-3 bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="VSOP shares">
    </div>

    <div class="flex">
      <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
        Base Price
      </span>
      <input v-model="basePrice" type="text" class="rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Base Price">
    </div>

      <Multiselect
          v-model="grantDate"
          :options="timeframes"
          :searchable=true
          label="name"
          placeholder="Base Price Period"
          valueProp="name"
      />
  </div>

  <p v-if="vsopFromRole && vsopFromRole != optionsEntered && !salaryIsShown" class="flex w-1/3">
    <small>
      For the currently selected role we grant {{ vsopFromRole }} virtual stock options.
      Click <a class="no-underline hover:underline text-orange-600" href="" @click="setOptions">here</a>
      to use that amount in the calculation above.
    </small>
  </p>

  <div class="overflow-x-auto rounded-lg border border-gray-200 mt-4 mb-4" v-if="optionsEntered && (grantDate || basePrice)">
    <table class="w-full divide-y-2 divide-gray-200 bg-white text-sm">
      <thead class="text-left">
        <tr>
          <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Company Valuation</th>
          <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Est. VSOP Gain</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr v-for="valuation in valuations" class="">
          <td class="whitespace-nowrap px-4 py-2 text-gray-900">{{formatAmount(valuation)}}</td>
          <td class="whitespace-nowrap px-4 py-2 text-gray-900">{{ formatAmount(capitalGained(valuation))}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <hr />
  <div class="mt-4 mb-2 text-gray-500">
    <div class="disclaimer-title text-l">Estimated VSOP Calculation</div>
    <div class="disclaimer">
      The <span class="disclaimer-important">estimated</span> VSOP gain is calculated in the following way: <br/>
      Est. VSOP Gain = # VSOP Shares allotted x (Company Valuation Price per Share - Base Price per Share).
      The Estimated VSOP Gain number assumes a 100% exit / divestment. All numbers are stated as gross numbers; please note that any proceeds from the VSOP will be amongst others subject to taxation, social surcharges etc.
    </div>
    <div class="disclaimer-title text-l mt-4">Disclaimer</div>
    <div class="disclaimer">
      <p class="mt-2">This calculation is a simplified calculation and uses certain assumptions. It is provided purely for information purposes to assist you in assessing a potential value of your grants under the VSOP in certain scenarios.</p>
      <p class="mt-2">The valuation scenarios do not take into account vesting schedules and potential future dilution due to issuance of add'l shares, financings and other reasons. <br /></p>
      <p class="mt-2">The gain could also be lower due to transaction costs.<br /></p>
      <p class="mt-2">
        The information provided is not guaranteed and may be subject to change at any time without notice. This is not, and shall not be deemed, an offer, has no legal effect, and should not be relied upon in any way.
        Only the terms of the VSOP and the allotment letter issued thereunder have legal effect and are binding and shall always prevail over any information provided in this simplified calculation.
      </p>
      <p class="mt-2">This calculator does not account for taxes. Camunda cannot provide advice on tax, legal or financial matters - you should consult with a specialist.
      Find more information in our <a class="no-underline hover:underline text-orange-600" href="https://confluence.camunda.com/pages/viewpage.action?spaceKey=BP&title=VSOP+FAQ">handbook</a>.</p>
    </div>
  </div>
  <hr />
</template>

<script>

import { formatAmount } from "../shared/utils";
import Multiselect from '@vueform/multiselect'

export default {
  name: 'Equity',
  components: {
    Multiselect
  },
  data: function() {
    return {
      optionsEntered: null,
      grantDate: null,
      basePrice: null
    }
  },
  props: {
    vsopFromRole: Number,
    valuations: Array,
    timeframes: Array,
    salaryIsShown: Boolean,
    currentLevel: String
  },
  updated() {
    if (this.salaryIsShown) {
      this.optionsEntered = this.vsopFromRole
    }
  },
  mounted () {
    this.grantDate = this.timeframes[0].name
  },
  computed: {
    currentGrantDefinition() {
      if (this.grantDate === null) {
        return null
      }

      return this.timeframes.find(timeframe => {
        return timeframe.name === this.grantDate
      })
    }
  },
  methods: {
    setOptions(event) {
      event.preventDefault();
      this.optionsEntered = this.vsopFromRole;
    },
    capitalGained(valuation) {
      if (valuation === 0 || this.basePrice === null) {
        return 0
      }

      const valuationBasePriceUnit = valuation / 13998600
      const stockPriceMinusBasePrice = valuationBasePriceUnit - this.basePrice

      let total = Math.round(stockPriceMinusBasePrice * this.optionsEntered)
      if (total < 0) {
        return 0
      }
      return total
    },
    formatAmount
  },
  watch: {
    grantDate(newGrantDate) {
      if (newGrantDate) {
        this.basePrice = this.currentGrantDefinition.stock_price
      }
    }
  }
}
</script>

<style scoped>
.equity-box {
    background-color: hsl(210, 9%, 96%);
  height: 100%;
  padding: 10px;
}

.disclaimer {
    font-size: 0.85em;
  text-align: justify;
    font-style: italic;
}
.disclaimer-important {
  text-decoration: underline;
  font-weight: bold;
}

.disclaimer-title{
    font-style: italic;
    text-align: justify;
}
</style>
