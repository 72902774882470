<template>
  <hr />
  <p class="text-xl mt-2">Total Cash</p>

  <div class="vp-level" v-if="isVPLevelOrUp">
      For this job role we do not show the salary component.
  </div>

  <div class="no-benchmark" v-if="missingBenchmark">
    For this job role, we do not have the ranges yet. Please reach out to Total Rewards if you need them.
  </div>

  <div class="grid grid-cols-3 gap-4 lg:grid-cols-3" v-if="!isVPLevelOrUp && !missingBenchmark">
    <div>
      <div class="compensation base-compensation-box text-center">
        <div v-if="currentBenchmarkDetails.calculate_from_base && !hideVariablePayDetails">Minimum Annual Base Pay</div>
        <div v-else class="base-compensation-box-title">Minimum Annual Total Cash</div>
        <div v-if="!localCurrencyBasePay" class="base-compensation-box-amount">{{formatAmount(minimumBasePay, '$')}}</div>
        <div v-if="localCurrencyBasePay" class="base-compensation-box-amount">{{ formatAmount(localCurrencyMinimumBasePay, localCurrency.symbol) }}</div>
      </div>
    </div>

    <div>
      <div class="compensation base-compensation-box text-center">
        <div v-if="currentBenchmarkDetails.calculate_from_base && !hideVariablePayDetails">Median Annual Base Pay</div>
        <div v-else class="base-compensation-box-title">Median Annual Total Cash</div>
        <div v-if="!localCurrencyBasePay" class="base-compensation-box-amount">{{formatAmount(basePay, '$')}}</div>
        <div v-if="localCurrencyBasePay" class="base-compensation-box-amount">{{ formatAmount(localCurrencyBasePay, localCurrency.symbol) }}</div>
      </div>
    </div>

    <div class="">
      <div class="compensation base-compensation-box text-center">
        <div v-if="currentBenchmarkDetails.calculate_from_base && !hideVariablePayDetails">Maximum Annual Base Pay</div>
        <div v-else class="base-compensation-box-title">Maximum Annual Total Cash</div>
        <div v-if="!localCurrencyBasePay" class="base-compensation-box-amount">{{formatAmount(maximumBasePay, '$')}}</div>
        <div v-if="localCurrencyBasePay" class="base-compensation-box-amount">{{ formatAmount(localCurrencyMaximumBasePay, localCurrency.symbol) }}</div>
      </div>
    </div>

    <div class="mt-2" v-if="isRoleWithVariablePay && !hideVariablePayDetails">
      <div v-if="currentBenchmarkDetails.calculate_from_base">
        This role’s Total Cash is inclusive of a {{(currentBenchmarkDetails.variable_percentage) * 100}}% variable component calculated from the base pay.
      </div>
      <div v-else>
        This role’s Total Cash is inclusive of a base and variable component on a {{(1 - currentBenchmarkDetails.variable_percentage) * 100}}/{{currentBenchmarkDetails.variable_percentage * 100}} split.
      </div>
    </div>
  </div>

  <div class="salary-variable-pay" v-if="isRoleWithVariablePay && !hideVariablePayDetails">
    Use the Total Cash box below to calculate the base and variable component based upon this split:

    <div class="flex flex-wrap -mx-1 mt-1">
      <div class="flex w-1/3 pr-2">
      <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
        Total Cash
      </span>
        <input v-model="enteredTotalPay" type="text" class="rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="flex w-1/3 pr-2">
      <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
        Base Salary
      </span>
        <input disabled v-model="enteredBasePay" type="text" class="rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="flex w-1/3">
      <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
        Variable Pay
      </span>
        <input disabled v-model="enteredVariablePay" type="text" class="rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
    </div>
  </div>

  <div class="mt-2" v-if="isRoleWithVariablePay && hideVariablePayDetails">
    This role's Total Cash is inclusive of a base and variable component. Please review your sales commission plan for more details on the variable compensation of this role.
  </div>

  <div class="tr-performance-box p-4 mt-4" v-if="minimumFactor == 0.8">
    <div class="text-m font-semibold mb-2">Please select the relevant performance indicator</div>
    <div class="flex flex-wrap mt-4 gap-4 items-center">
      <div class="flex flex-col w-1/3">
        <Multiselect
            v-model="currentPerformanceIndicator"
            :options="performanceFactors"
            :searchable=true
            label="performance"
            placeholder="Select the performance indicator"
            valueProp="performance"
            class=""
          />
      </div>

      <div class="flex flex-col w-fit" v-if="currentPerformanceIndicator">
        <p v-if="localCurrency">
          Total Cash Performance Sub-Range for <b>{{currentPerformanceIndicator}}</b> is
          {{formatAmount(subRange[0], localCurrency.symbol)}} to {{formatAmount(subRange[1], localCurrency.symbol)}}
        </p>

        <p v-else>
          Total Cash Performance Sub-Range for <b>{{currentPerformanceIndicator}}</b> is
          {{formatAmount(subRange[0], '$')}} to {{formatAmount(subRange[1], '$')}}
        </p>
      </div>

      <span v-if="!isApplicant" class="text-sm">
        Learn more about our approach to placing new hires in the range by referring to <a class="no-underline hover:underline text-orange-600" href="https://drive.google.com/file/d/1pk0zYByr2ZF2XwznnHEhM3MhioKzHzAv/view">this graphic</a>.
      </span>
    </div>
  </div>
  <div class="mt-2 mb-2 text-gray-500">
    <div class="disclaimer">
        <div v-if="!localCurrency">
            The Camunda Total Rewards Calculator defaults to USD where an exchange rate is not already set. Please coordinate with Remote and Total Rewards for the actual currency options for this country.
        </div>
      The Total Rewards calculator is a tool to assist Camunda in determining a compensation package for new and existing employees. The results of the calculator are not binding. Written correspondence through a contract or letter of adjustment specify all official compensation changes. We reserve the right to change the calculator at any point in time.
    </div>
  </div>

  <hr />

</template>

<script>

import { formatAmount } from "../shared/utils";
import Multiselect from "@vueform/multiselect";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Salary",
  components: {
    Multiselect
  },
  data: function() {
    return {
      enteredTotalPay: null,
      enteredBasePay: null,
      enteredVariablePay: null,
      performanceFactors: ['Partly Performing', 'Fully Performing', 'Exceeding'],
      currentPerformanceIndicator: null
    }
  },
  props: {
    country: Object,
    localCurrency: Object,
    benchmarks: Object,
    role: Object,
    salesBenchmarks: Object,
    isApplicant: Boolean
  },
  computed: {
    minimumFactor() {
      if (this.currentBenchmarkDetails.range_spread) {
        return 1 - this.currentBenchmarkDetails.range_spread / 100
      }

      return 0.8
    },
    maximumFactor() {
      if (this.currentBenchmarkDetails.range_spread) {
        return 1 + this.currentBenchmarkDetails.range_spread / 100
      }

      return 1.2
    },
    subRange() {
      if (this.currentBenchmarkDetails.range_spread && this.currentBenchmarkDetails.range_spread > 0) {
        return null
      }

      if (this.localCurrency) {
        return this.localCurrencySubRange
      }
      if (this.currentPerformanceIndicator == "Partly Performing") {
        const minPoint = this.basePay * this.minimumFactor
        const maxPoint = this.basePay * 0.9
        return [minPoint, maxPoint]
      }

      if (this.currentPerformanceIndicator == "Fully Performing") {
        const minPoint = this.basePay * 0.9
        const maxPoint = this.basePay * 1.1
        return [minPoint, maxPoint]
      }

      if (this.currentPerformanceIndicator == "Exceeding") {
        const minPoint = this.basePay * 1.1
        const maxPoint = this.basePay * this.maximumFactor
        return [minPoint, maxPoint]
      }

      return null
    },
    localCurrencySubRange() {
      if (this.currentPerformanceIndicator == "Partly Performing") {
        const minPoint = this.localCurrencyBasePay * this.minimumFactor
        const maxPoint = this.localCurrencyBasePay * 0.9
        return [minPoint, maxPoint]
      }

      if (this.currentPerformanceIndicator == "Fully Performing") {
        const minPoint = this.localCurrencyBasePay * 0.9
        const maxPoint = this.localCurrencyBasePay * 1.1
        return [minPoint, maxPoint]
      }

      if (this.currentPerformanceIndicator == "Exceeding") {
        const minPoint = this.localCurrencyBasePay * 1.1
        const maxPoint = this.localCurrencyBasePay * this.maximumFactor
        return [minPoint, maxPoint]
      }

      return null
    },
    basePay() {
      return this.currentBenchmarkForLevel * this.country.factor
    },
    minimumBasePay() {
      return this.basePay * this.minimumFactor
    },
    maximumBasePay() {
      return this.basePay * this.maximumFactor
    },
    localCurrencyBasePay() {
      if (!this.localCurrency) {
        return null
      }

      return this.basePay * this.localCurrency.from_usd_factor
    },
    localCurrencyMinimumBasePay() {
      if (!this.localCurrencyBasePay) {
        return null
      }

      return this.localCurrencyBasePay * this.minimumFactor
    },
    localCurrencyMaximumBasePay() {
      if (!this.localCurrencyBasePay) {
        return null
      }

      return this.localCurrencyBasePay * this.maximumFactor
    },
    isVPLevelOrUp() {
      return this.role.grade >= 12
    },
    currentBenchmarkDetails() {
      if (this.role.use_sales_benchmarks) {
        return this.salesBenchmarks.find(bm => {
          return bm.job_role === this.role.name
        })
      }

      return this.benchmarks.find(bm => {
        return bm.job_family === this.role.job_family
      })
    },
    currentBenchmarkForLevel() {
      if (!this.currentBenchmarkDetails || !this.role.grade) {
        return null
      }

      if (this.role.use_sales_benchmarks) {
        return this.currentBenchmarkDetails.base_pay
      }

      return this.currentBenchmarkDetails.grade_benchmarks[this.role.grade]
    },
    missingBenchmark() {
      return (this.role && !this.currentBenchmarkForLevel)
    },
    isRoleWithVariablePay() {
      if (!this.currentBenchmarkDetails) {
        return null
      }

      return this.currentBenchmarkDetails.has_variable_pay
    },
    hideVariablePayDetails() {
      if (!this.isRoleWithVariablePay) {
        return false
      }

      return this.currentBenchmarkDetails.hide_variable_pay
    }
  },
  methods: {
    formatAmount,
    calculateVariablePay() {
      if (!this.basePay || !this.isRoleWithVariablePay) {
        return null;
      }

      if (this.currentBenchmarkDetails.calculate_from_base) {
        const factor = 1 + this.currentBenchmarkDetails.variable_percentage
        const tempBasePay = this.enteredTotalPay / factor
        return Math.round(tempBasePay * this.currentBenchmarkDetails.variable_percentage)
      }
      return Math.round(this.enteredTotalPay * this.currentBenchmarkDetails.variable_percentage)
    }
  },
  watch: {
    enteredTotalPay(newTotalPay) {
      const calculatedVariablePay = this.calculateVariablePay()
      this.enteredVariablePay = this.formatAmount(calculatedVariablePay, this.localCurrency.symbol)
      this.enteredBasePay = this.formatAmount(newTotalPay - calculatedVariablePay, this.localCurrency.symbol)
    }
  }
}
</script>

<style scoped>
.base-compensation-box {
  background-color: hsl(210, 9%, 96%);
  height: 100%;
  padding: 10px;
}

.base-compensation-box-title {
  padding-bottom: 10px;
}

.base-compensation-box-amount {
  font-size: 25px;
}

.vp-level {
  font-style: italic;
}

.disclaimer {
  text-align: justify;
  font-size: 0.85em;
  font-style: italic;
}

.salary-variable-pay {
  border: 1px solid grey;
  border-radius: 3px;
  height: 100%;
  padding: 10px;
}

.text-m {
  font-size: 1.15rem;
  line-height: 1.30rem;
}
.tr-performance-box {
  border: 0.5px solid #fb923c
}
</style>