export const formatAmount = function (amount, currencyCode, rounding = true) {
    if (amount === null || amount === undefined) {
        return 'no amount'
    }

    let roundedAmount = amount;
    if (rounding) {
        roundedAmount = Math.ceil(amount / 100) * 100;
    }

    const formattedAmount = roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (currencyCode) {
        return currencyCode + formattedAmount;
    }

    return '€' + formattedAmount;
};
