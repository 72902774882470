<template>
  <transition name="modal-fade w-full justify-center mx-auto m-2 overflow-auto">
    <div class="modal-backdrop w-full justify-center mx-auto m-2 overflow-auto">
      <div ref="modal" class="modal w-1/2"
            tabindex="0"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           @keydown.esc="close"
      >
        <header
            class="modal-header"
            id="modalTitle"
        >
          <slot name="header">
            <p class="text-blaze-orange-600">
              {{ participant.name}}
            </p>
          </slot>

        </header>

        <section
            class="modal-body"
            id="modalDescription"
        >
          <slot name="body">
            <template v-for="comment in localComments">
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <p class="text-sm font-bold text-gray-900">{{comment.user_email}}</p>
                  <p class="text-xs text-gray-500 ml-2">{{comment.created_at}}</p>
                </div>
                <p class="text-sm text-gray-900">{{comment.comment}}</p>
              </div>
              <hr class="my-2" />
            </template>

            <p v-if="localComments.length == 0" class="text-sm text-gray-900">No comments yet</p>

            <div class="mt-12">
              <at-ta :members="mentionables">
                <textarea v-model="message" id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Comment"></textarea>
              </at-ta>
            </div>

            <button class="mt-2 float-right text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="submitComment">
              Submit
            </button>
          </slot>


        </section>

        <footer class="modal-footer">
          <p class="text-right text-sm">
            <button
                type="button"
                class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                @click="close"
                aria-label="Close"
            >
              Close
            </button>
          </p>

        </footer>
      </div>
    </div>
  </transition>
</template>

<script>

import AtTa from 'vue-at/dist/vue-at-textarea' // for textarea

export default {
  name: 'ParticipantComments',
  components: {  AtTa },
  props: {
    participant: {
      type: Object,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
    mentionables: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      message: '',
      localComments: this.comments
    }
  },
  mounted() {
    this.$refs.modal.focus();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submitComment() {
      if (this.message == null || this.message == '') {
        return
      }

      let data = {
        comment: this.message
      }

      this.localComments.push({
        user_email: 'You',
        comment: this.message,
        created_at: new Date().toLocaleString()
      })

      this.message = null

      await fetch(` /cycle_participants/${this.participant.id}/cycle_participant_comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        },
        body: JSON.stringify({cycle_participant_comment: data})
      })
    }
  },
};
</script>
