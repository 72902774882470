<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           @keydown.esc="close"
           ref="modal"
           tabindex="0"
      >
        <header
            class="modal-header"
            id="modalTitle"
        >
          <slot name="header">
            <p class="text-blaze-orange-600">
              {{ roleDetails.name}}
            </p>
          </slot>

        </header>

        <section
            class="modal-body"
            id="modalDescription"
        >
          <slot name="body">
            <p>Department: {{ roleDetails.department }}</p>
            <p>Division: {{ roleDetails.division }}</p>
            <p>Job Family: {{ jobFamily }}</p>
            <p>Grade: {{roleDetails.grade}}</p>
            <p>Internal ID: {{roleDetails.internal_id}}</p>
          </slot>

        </section>

        <footer class="modal-footer">
          <p class="text-right text-sm">
            <button
                type="button"
                class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                @click="close"
                aria-label="Close"
            >
              Close
            </button>
          </p>

        </footer>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'JobDetailsView',
  props: {
    roleDetails: {
      type: Object,
      required: true,
    },
    jobFamily: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.$refs.modal.focus();
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>