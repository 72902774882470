<template>
  <tr>
    <!-- Employee basic details  -->
    <td class="sticky left-0 whitespace-nowrap bg-white px-4 py-2 font-medium text-gray-900">
      <span @click="toggleShowDetails" class="cursor-pointer no-underline hover:underline hover:text-orange-600">
          {{participant.name}}
        </span>

      <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Job Title: </span> {{participant.job_title}}</p>
      <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Start Date: </span> {{participant.start_date}}</p>
      <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">PI: </span>{{participant.performance_indicator}}</p>
      <p v-if="showPayDetails" class="w-full">
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Grade: </span>{{participant.grade}}</p>
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Tenure: </span>{{participant.tenure}}</p>
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Department: </span>{{participant.department}}</p>
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Division: </span>{{participant.division}}</p>
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Last Increase At: </span>{{participant.last_increase_at || "N/A"}}</p>
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Last Increase: </span>
          <span v-if="participant.start_date == participant.last_increase_at && participant.last_increase_percentage == '0.0'">New Hire</span>
          <span v-else>{{participant.last_increase_percentage}}%</span>
        </p>
      </p>
    </td>

    <!-- Current Total Cash -->
    <td class="whitespace-nowrap px-4 py-2">
      {{currency}}{{new Intl.NumberFormat('en-US').format(Math.round(totalCash))}}
      <p class="text-xs" v-if="variableSalary">
        {{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(baseSalary))}} + {{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(variableSalary))}}
      </p>
      <p class="text-xs" v-if="variableSalary">
        <span>
          Variable: {{currentVariablePercentage}}%

          <span >{{calculatedFromBase ? 'of base' : 'of total'}}</span>
        </span>
      </p>
    </td>

    <!-- Compa Ratio -->
    <td class="whitespace-nowrap text-center px-4 py-2">
      <div v-if="salaryBenchmark && salaryBenchmark > 0">
        <div class="flex flex-col space-y-2 p-2 w-32" v-if="participant.new_compa_ratio <= compaRange[1] && participant.new_compa_ratio >= compaRange[0]">
          <input type="range" class="w-full accent-teal-400" :min="compaRange[0]" :max="compaRange[1]" step="0.1" :value="participant.compa_ratio" />
        </div>

        <div class="flex flex-col space-y-2 p-2 w-32" v-else>
          <input type="range" class="w-full accent-red-400" :min="compaRange[0]" :max="compaRange[1]" step="0.1" :value="participant.compa_ratio" />
        </div>
        {{Number(participant.compa_ratio).toFixed(2)}}
      </div>
      <span v-else> N/A </span>
    </td>

    <!-- Benchmark Range -->
    <td class="whitespace-nowrap text-center">
      <div v-if="salaryBenchmark && salaryBenchmark > 0">
        <p>{{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(benchmarkRange[0] / 100) * 100)}} - {{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(benchmarkRange[1] / 100) * 100)}}</p>
        <p class="text-xs" v-if="adjustBenchmarks == 'No'">
          Median: {{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(salaryBenchmark / 100) * 100)}}
        </p>
      </div>
      <p v-else class="text-center">
        N/A
      </p>
    </td>

    <!-- Suggested % Increase -->
    <td class="whitespace-nowrap px-4 py-2 text-center">
      {{participant.min_recommendation}}% - {{participant.max_recommendation}}%
    </td>

    <!-- Base Salary % Increase -->
    <td class="whitespace-nowrap text-center">
      <div v-if="editable" class="flex items-center rounded border" :class="{'border-red-500': nobodyHasVariablePay && baseSalaryPercentageIncrease > (participant.max_recommendation) + 0.4}">
        <button type="button" class="size-10 leading-10 text-gray-600 transition hover:opacity-75" @click="decreaseBasePayPercentageIncrease">
          &minus;
        </button>

        <input class="cursor-pointer h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none" :class="{'border-red-500' : nobodyHasVariablePay && baseSalaryPercentageIncrease > (participant.max_recommendation + 0.4)}" type="number" v-model="baseSalaryPercentageIncrease" step="0.1" min="0"/>

        <button type="button" class="size-10 leading-10 text-gray-600 transition hover:opacity-75" @click="increaseBasePayPercentageIncrease">
          &plus;
        </button>
      </div>

      <span v-else class="text-center" :class="{'text-red-500' : nobodyHasVariablePay && baseSalaryPercentageIncrease > (participant.max_recommendation + 0.4)}">
        {{baseSalaryPercentageIncrease}}%
      </span>
    </td>

    <!-- Variable Pay % Increase -->
    <td v-if="!nobodyHasVariablePay" class="whitespace-nowrap text-center">
      <div v-if="editable && variableSalary" class="w-fit ml-6">
        <div class="flex rounded border items-center w-fit content-center">
          <button type="button" class="size-10 leading-10 text-gray-600 transition hover:opacity-75" @click="decreaseVariablePayPercentageIncrease">
            &minus;
          </button>

          <input
              type="number"
              v-model="variablePayPercentageIncrease"
              step="0.1"
              min="0"
              class="cursor-pointer h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
          />

          <button type="button" class="size-10 leading-10 text-gray-600 transition hover:opacity-75" @click="increaseVariablePayPercentageIncrease">
            &plus;
          </button>
        </div>
      </div>
      <p class="text-xs" v-else-if="!variableSalary">N/A</p>
      <p v-else>{{variablePayPercentageIncrease}}%</p>
    </td>

    <!-- Total % Increase -->
    <td  v-if="!nobodyHasVariablePay" class="whitespace-nowrap text-center">
      <span :class="{'text-red-500 border-gray-400' : Number(totalCashPercentageIncrease) > (participant.max_recommendation + 0.4)}">
        {{totalCashPercentageIncrease}}%
      </span>
    </td>

    <!-- New Total Cash -->
    <td class="whitespace-nowrap text-center">
      <span v-if="variableSalary">
        {{currency}}{{new Intl.NumberFormat('en-US').format(Math.round(newBaseSalary + newVariablePay))}}
        <p class="text-xs">
          {{currency}}{{new Intl.NumberFormat('en-US').format(Math.round(newBaseSalary))}} + {{currency}}{{new Intl.NumberFormat('en-US').format(Math.round(newVariablePay))}}
        </p>

        <p class="text-xs">
          <span>
            Variable: {{newVariablePercentage}}%
            <span >{{calculatedFromBase ? 'of base' : 'of total'}}</span>
          </span>
        </p>
      </span>
      <span v-else>
        {{currency}}{{new Intl.NumberFormat('en-US').format(roundedNewBaseSalary)}}
      </span>
    </td>

    <!-- New Compa -->
    <td class="whitespace-nowrap text-center">
      <span v-if="salaryBenchmark && salaryBenchmark > 0" :class="{'text-red-500' : newCompaRatio && newCompaRatio > 1.2 }">
        <div class="flex flex-col space-y-2 p-2 w-32" v-if="participant.new_compa_ratio <= compaRange[1] && participant.new_compa_ratio >= compaRange[0]">
          <input type="range" class="w-full accent-teal-400" :min="compaRange[0]" :max="compaRange[1]" step="0.01" v-model="newCompaRatioForSlider" />
        </div>

        <div class="flex flex-col space-y-2 p-2 w-32" v-else>
          <input type="range" class="w-full accent-red-400" :min="compaRange[0]" :max="compaRange[1]" step="0.01" v-model="newCompaRatioForSlider" />
        </div>

        <span v-if="baseSalaryPercentageIncrease > 0 || variablePayPercentageIncrease > 0">{{Number(newCompaRatio).toFixed((2))}}</span>
        <span v-else>{{Number(participant.compa_ratio).toFixed(2)}}</span>
      </span>
      <span v-else>
        N/A
      </span>
    </td>

    <!-- Actions -->
    <td class="whitespace-nowrap px-4 py-2">
      <div class="text-gray-900 whitespace-no-wrap ml-3 ">
        <span @click="displayModal" class="inline-flex items-baseline cursor-pointer" v-if="comments && comments.length > 0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 self-center w-5 h-5 rounded-full mx-1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
          </svg>
        </span>
        <span @click="displayModal" class="inline-flex items-baseline cursor-pointer self-center w-5 h-5 rounded-full mx-1" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
          </svg>
        </span>

        <span @click="showHistoryModal" class="w-6 h-6 self-center w-5 h-5 rounded-full mx-1 inline-flex items-baseline cursor-pointer" v-if="participantSubmissions.length > 0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        </span>
      </div>
    </td>
  </tr>


  <div v-if="showModal">
    <ParticipantComments
        :participant="participant"
        :comments="comments"
        :mentionables="mentionables"
        @close="closeModal"
    />
    </div>

  <div v-if="showHistory">
    <ParticipantHistory
        :participant="participant"
        :participant-submissions="participantSubmissions"
        @close="closeHistoryModal"
    />
  </div>

</template>

<script>

import ParticipantComments from './ParticipantComments.vue'
import ParticipantHistory from './ParticipantHistory.vue'

export default {
  name: "ParticipantDetailTableVersion",
  components: {ParticipantComments, ParticipantHistory},
  props: {
    participant: Object,
    useLocalCurrency: Boolean,
    adjustBenchmarks: String,
    cycle: Object,
    editable: Boolean,
    nobodyHasVariablePay: Boolean,
    currencyRate: String,
    comments: Array,
    mentionables: Array,
    participantSubmissions: Array
  },
  data() {
    return {
      baseSalaryPercentageIncrease: this.participant.base_salary_percentage_increase || 0.0,
      variablePayPercentageIncrease: this.participant.variable_pay_percentage_increase || 0.0,
      showModal: false,
      showPayDetails: false,
      needsSaving: false,
      showHistory: false,
      currentCompaForSlider: this.participant.compa_ratio || 1,
      newCompaRatioForSlider: this.participant.new_compa_ratio || 1
    }
  },
  mounted() {
    setInterval(this.updateParticipant,5000)

    this.$nextTick(() => {
      this.setParamsFromUrl(window.location.href);
    })
  },
  computed: {
    // These are computed properties that are not dependent on the baseSalaryPercentageIncrease or variablePayPercentageIncrease
    calculatedFromBase() {
      return this.participant.division == 'Customer Success' ? false : true
    },
    baseSalary() {
      if (this.useLocalCurrency) {
        return Number(this.participant.base_salary)
      } else {
        return Number(this.participant.base_salary_cycle_currency)
      }
    },
    variableSalary() {
      if (this.useLocalCurrency) {
        return Number(this.participant.variable_pay)
      } else {
        return Number(this.participant.variable_pay_cycle_currency)
      }
    },
    totalCash() {
      return this.baseSalary + this.variableSalary
    },
    currentVariablePercentage() {
      if (this.calculatedFromBase) {
        return (this.variableSalary / this.baseSalary * 100).toFixed(1)
      }

      return (this.variableSalary / this.totalCash * 100).toFixed(1)
    },
    salaryBenchmark() {
      if (this.participant.benchmark_in_local_currency == null) {
        return null
      }
      if (this.useLocalCurrency) {
        return Math.ceil(this.participant.benchmark_in_local_currency)
      } else {
        return Math.ceil(this.participant.benchmark_in_cycle_currency / 100) * 100
      }
    },
    currency() {
      if (this.useLocalCurrency) {
        return this.participant.currency_symbol
      } else {
        return "€"
      }
    },
    compaRange() {
      if (this.participant.performance_indicator == null || this.adjustBenchmarks == "No") {
        return [0.8, 1.2]
      }

      if (this.participant.performance_indicator == "Developing" || this.participant.performance_indicator == "Needs Improvement" || this.participant.performance_indicator == "Partly Performing") {
          return [0.8, 0.9]
      }

      if (this.participant.performance_indicator == "Performing" || this.participant.performance_indicator == "Fully Performing") {
          return [0.9, 1.1]
      }

      if (this.participant.performance_indicator == "Exceeding") {
          return [1.1, 1.2]
      }

      return [0.8, 1.2]
    },
    benchmarkRange() {
      if (this.participant.performance_indicator == null || this.adjustBenchmarks == "No") {
        return [this.salaryBenchmark * 0.8, this.salaryBenchmark * 1.2]
      }

      if (this.participant.performance_indicator == "Developing" || this.participant.performance_indicator == "Needs Improvement" || this.participant.performance_indicator == "Partly Performing") {
        return [(this.salaryBenchmark * 0.8).toFixed(), (this.salaryBenchmark * 0.9).toFixed()]
      }

      if (this.participant.performance_indicator == "Performing" || this.participant.performance_indicator == "Fully Performing") {
        return [(this.salaryBenchmark * 0.9).toFixed(), (this.salaryBenchmark * 1.1).toFixed()]
      }

      if (this.participant.performance_indicator == "Exceeding") {
        return [(this.salaryBenchmark * 1.1).toFixed(), (this.salaryBenchmark * 1.2).toFixed()]
      }

      return [null,null]
    },
    // End of computed properties that are not dependent on the baseSalaryPercentageIncrease or variablePayPercentageIncrease


    // These are computed properties that are dependent on the baseSalaryPercentageIncrease or variablePayPercentageIncrease
    totalCashPercentageIncrease() {
      if (this.baseSalaryPercentageIncrease == 0 && this.variablePayPercentageIncrease == 0) {
        return 0
      }
      
      if (this.variableSalary) {
        const totalCash = this.newBaseSalary + this.newVariablePay
        return ((totalCash - this.totalCash) / this.totalCash * 100).toFixed(1)
      }
      return this.baseSalaryPercentageIncrease
    },
    // new base salary calculations
    newLocalBaseSalary() {
      return Number(this.participant.base_salary) * this.baseSalaryPercentageIncrease / 100.0 + Number(this.participant.base_salary)
    },
    newBaseSalary() {
      if (this.useLocalCurrency) {
        return this.newLocalBaseSalary
      } else {
        return this.newLocalBaseSalary / this.currencyRate
      }
    },
    roundedNewLocalBaseSalary() {
      return Math.ceil(this.newLocalBaseSalary / 100) * 100
    },
    roundedNewBaseSalary() {
      if (this.useLocalCurrency || this.participant.currency == 'EUR') {
        return this.roundedNewLocalBaseSalary
      }

      return Math.round(this.newBaseSalary)
    },
    // new variable pay calculations
    newLocalVariablePay() {
      return Number(this.participant.variable_pay) * (1 + (this.variablePayPercentageIncrease / 100.0))
    },
    newVariablePay() {
      if (this.useLocalCurrency) {
        return this.newLocalVariablePay
      }
      else {
        return this.newLocalVariablePay / this.currencyRate
      }
    },
    newVariablePercentage() {
      if (this.calculatedFromBase) {
        return (this.newVariablePay / this.newBaseSalary * 100).toFixed(1)
      }

      return Math.round(this.newVariablePay / (this.newTotalCash) * 100).toFixed(1)
    },
    // new total cash calculations
    newTotalCash() {
      return this.newBaseSalary + this.newVariablePay
    },
    newCompaRatio() {
      const calc = ((this.newTotalCash - this.salaryBenchmark) / this.salaryBenchmark) + 1
      return Math.round(calc * 100) / 100
    }
  },
  watch: {
    baseSalaryPercentageIncrease(oldValue, newValue) {
      if (oldValue == newValue) {
        return
      }
      this.needsSaving = true
    },
    variablePayPercentageIncrease(oldValue, newValue) {
      if (oldValue == newValue) {
        return
      }
      this.needsSaving = true
    },
    newCompaRatioForSlider(newValue, oldValue) {
      if (oldValue == newValue) {
        return
      }

      if (Number(newValue) <= Number(this.participant.compa_ratio)) {
        //  we do not want to take money away from the employee
        this.newCompaRatioForSlider = this.participant.compa_ratio
        this.baseSalaryPercentageIncrease = 0.0
        this.variablePayPercentageIncrease = 0.0
        return
      }

      let whatTheTotalCashShouldBe = this.salaryBenchmark * newValue
      let totalNewSalary = this.totalCash + (whatTheTotalCashShouldBe - this.totalCash)

      if (this.participant.variable_pay && this.participant.variable_pay > 0) {
        totalNewSalary = Math.round(totalNewSalary)
        let percentage = (totalNewSalary - this.totalCash) / this.totalCash * 100
        this.baseSalaryPercentageIncrease = percentage.toFixed(1)
        this.variablePayPercentageIncrease = percentage.toFixed(1)
      }
      else {
        if (this.useLocalCurrency) {
          totalNewSalary = Math.ceil(totalNewSalary / 100) * 100
        }
        else {
          totalNewSalary = Math.round(totalNewSalary)
        }
        // determine the percentage between two numbers
        let percentage = (totalNewSalary - this.baseSalary) / this.baseSalary * 100
        this.baseSalaryPercentageIncrease = percentage.toFixed(1)
      }
    }
  },
  methods: {
    setParamsFromUrl(url) {
      const currentParams = (new URL(url)).searchParams
      const participantParam = currentParams.get('participant')
      if (participantParam == this.participant.id) {
        this.showModal = true
      }
    },
    increaseVariablePayPercentageIncrease() {
      this.variablePayPercentageIncrease = (Number(this.variablePayPercentageIncrease) + 0.10).toFixed(1)
    },
    decreaseVariablePayPercentageIncrease() {
      this.variablePayPercentageIncrease = (Number(this.variablePayPercentageIncrease) - 0.10).toFixed(1)
    },
    increaseBasePayPercentageIncrease() {
      this.baseSalaryPercentageIncrease = (Number(this.baseSalaryPercentageIncrease) + 0.10).toFixed(1)
    },
    decreaseBasePayPercentageIncrease() {
      this.baseSalaryPercentageIncrease = (Number(this.baseSalaryPercentageIncrease) - 0.10).toFixed(1)
    },
    closeModal() {
      this.showModal = false
    },
    displayModal() {
      this.showModal = true
    },
    showHistoryModal() {
      this.showHistory = true
    },
    closeHistoryModal() {
      this.showHistory = false
    },
    toggleShowDetails() {
      this.showPayDetails = !this.showPayDetails
    },
    async updateParticipant() {
      if (!this.needsSaving) {
        return
      }

      let updateParticipant = this.participant
      updateParticipant.base_salary_percentage_increase = this.baseSalaryPercentageIncrease
      updateParticipant.variable_pay_percentage_increase = this.variablePayPercentageIncrease
      updateParticipant.new_compa_ratio = this.newCompaRatio
      updateParticipant.total_percentage_increase = this.totalCashPercentageIncrease

      if (this.variableSalary) {
        updateParticipant.new_variable_percentage = this.newVariablePercentage

        if (this.baseSalaryPercentageIncrease == 0 || this.baseSalaryPercentageIncrease == 0.0) {
          updateParticipant.new_base_salary = this.participant.base_salary
          updateParticipant.new_base_salary_in_cycle_currency = this.participant.base_salary_cycle_currency
        } else {
          updateParticipant.new_base_salary = this.newLocalBaseSalary
          updateParticipant.new_base_salary_in_cycle_currency = Math.round(this.newLocalBaseSalary / this.currencyRate)
        }

        if (this.variablePayPercentageIncrease == 0 || this.variablePayPercentageIncrease == 0.0) {
          updateParticipant.new_variable_pay = this.participant.variable_pay
          updateParticipant.new_variable_pay_in_cycle_currency = this.participant.variable_pay_cycle_currency
        } else {
          updateParticipant.new_variable_pay = this.newLocalVariablePay
          updateParticipant.new_variable_pay_in_cycle_currency = Math.round(this.newLocalVariablePay / this.currencyRate)
        }
      }
      else {
        if (this.baseSalaryPercentageIncrease == 0 || this.baseSalaryPercentageIncrease == 0.0) {
          updateParticipant.new_base_salary = this.participant.base_salary
          updateParticipant.new_base_salary_in_cycle_currency = this.participant.base_salary_cycle_currency
        } else {
          updateParticipant.new_base_salary = this.roundedNewLocalBaseSalary
          updateParticipant.new_base_salary_in_cycle_currency = Math.round(this.roundedNewLocalBaseSalary / this.currencyRate)
        }
      }

      this.$emit('update-participant',  updateParticipant)

      await fetch(`/compensation_cycles/${this.cycle.id}/cycle_participants/${this.participant.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        },
        body: JSON.stringify({
          base_salary_percentage_increase: this.baseSalaryPercentageIncrease,
          variable_pay_percentage_increase: this.variablePayPercentageIncrease,
          new_base_salary: updateParticipant.new_base_salary,
          new_variable_pay: updateParticipant.new_variable_pay,
          new_base_salary_in_cycle_currency: updateParticipant.new_base_salary_in_cycle_currency,
          new_variable_pay_in_cycle_currency: updateParticipant.new_variable_pay_in_cycle_currency,
          new_variable_percentage: updateParticipant.new_variable_percentage
        })
      }).then((data) => {
        this.needsSaving = false
      });
    }
  }
}
</script>
