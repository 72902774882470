<template>
  <div id="app">
    <div v-if="multiplePlanners" class="cursor-pointer hover:text-blaze-orange-600" @click="hidePlanner">
      &#x2190; Back
    </div>

    <div class="my-3 flex gap-4" v-if="cycle && showHeader">
      <div class="w-1/3 flex flex-col ">
        <!-- Cycle budget information-->
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color flex-1">
          <h2 class="font-semibold text-xl tracking-tight text-color mb-4">Budget for {{planner.name}}</h2>
          <p>Total Budget: €{{new Intl.NumberFormat('en-US').format(Math.round(planner.budget))}}</p>
          <p>Total Spend: €{{new Intl.NumberFormat('en-US').format(Math.round(totalSpend))}}</p>
          <p>Budget Remaining:
            <span :class="{'text-red-500': budgetRemaining < 0}">€{{new Intl.NumberFormat('en-US').format(Math.round(budgetRemaining))}}</span>
          </p>
        </div>
      </div>
      <!-- Cycle Settings -->
      <div class="w-1/3 flex flex-col">
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
          <h2 class="font-semibold text-xl tracking-tight text-color mb-4">Settings</h2>
          <div class="flex-1">
            <div class="">Worksheet Currency:
              <select v-model="worksheetCurrency" class="cursor-pointer border-gray-400 leading-tight text-sm">
                <option>Local Currency</option>
                <option>EUR</option>
              </select>
            </div>

            <div v-if="ineligibleParticipants.length > 0">
              <label class="mr-2">Show Ineligible Employees</label>
              <input type="checkbox" v-model="includeIneligibleParticipants" class="cursor-pointer border-gray-400 400 leading-tight text-sm">
            </div>

            <div class="mt-2">
              Adjust benchmarks to PI:
              <select v-model="usePerformanceIndicator" class="cursor-pointer border-gray-400 400 leading-tight text-sm">
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>

            <small>
              If yes, the range shown will be the sub-range aligned with the PI assigned in Q4 2023. There may be times where a Camundi is not aligned to their performance sub range.
              <svg v-tippy="'While we aim to align with the performance sub-range, it\'s important to remember that our compensation ranges are designed to be relevant over the lifespan of a Camundi in their current role, which can span several years. Strong performance should be rewarded with appropriate increase. However, in cases where someone is new to a role, whether as a new hire or through a promotion, and they are rated as exceeding, it doesn\'t necessarily imply an immediate alignment with the \'exceeding\' sub-portion of the range. Instead, it indicates that they should receive a higher percentage increase relative to someone with a \'performing\' rating. This approach ensures a balanced and equitable progression in compensation, reflecting both current performance and potential for growth in their role at Camunda.'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-flex items-baseline">
                <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
              </svg>
            </small>
          </div>
        </div>
      </div>

      <!-- Cycle Actions -->
      <div class="w-1/3 flex flex-col">
        <div class="flex-1 backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
          <h2 class="font-semibold text-xl tracking-tight text-color mb-4">Actions</h2>
          <div v-if="planner.status != 'approved'">
              <div v-if="submissionHappened">
                <button v-if="submission === 'approved'" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded cursor-not-allowed opacity-50">
                  Approved
                </button>

                <button v-if="submission == 'rejected'" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded cursor-not-allowed opacity-50">
                  Rejected
                </button>
              </div>

              <div v-else-if="sheetBelongsToUser && !submissionHappened">
                <!-- This is the section for the planner itself, they can send it out for approval -->
                <div v-if="inDraft">
                  <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="openSubmitModal">
                    Submit for Approval
                  </button>
                </div>

                <div v-else-if="submitted">
                  <button class="bg-green-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed">
                    Waiting for Approval
                  </button>
                </div>
              </div>

              <div v-else>
                <!-- this is section for the manager -->
                <div v-if="inDraft">
                  <p class="text-sm">We are waiting for {{planner.name}} to submit.</p>
                </div>

                <div v-else-if="submitted">
                  <!-- is it up to the current user to submit? -->
                  <div v-if="userNeedsToApprove">
                    <p class="mb-2">You need to review this sheet:</p>
                    <button class="mr-3 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-sm" @click="openApproveModal">
                      Approve
                    </button>
                    <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-sm" @click="openRejectModal">
                      Send Back
                    </button>
                  </div>

                  <div v-else>
                    <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded cursor-not-allowed opacity-50">
                      Pending Approval
                    </button>
                  </div>
                </div>
              </div>

            </div>

          <div class="absolute bottom-5">
            <a
                :href="exportLink"
                target="_blank"
                class="text-xs bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
            >
              Export
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="m-3 flex gap-3">
      <div class="flex-item flex-col">
        <div>Worksheet Currency:
          <select v-model="worksheetCurrency" class="cursor-pointer border-gray-400 leading-tight text-sm">
            <option>Local Currency</option>
            <option>EUR</option>
          </select>
        </div>
      </div>
      <div class="flex-item flex-col">
        <div>
          Adjust benchmarks to PI:
          <select v-model="usePerformanceIndicator" class="cursor-pointer border-gray-400 400 leading-tight text-sm">
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
      </div>

      <hr/>
    </div>

    <div class="overflow-x-auto mt-7">
      <table class="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead class="ltr:text-left rtl:text-right">
        <tr class="bg-gray-100">
          <th class="sticky left-0 text-left bg-gray-100 whitespace-nowrap px-4 py-5 font-medium text-gray-700 uppercase font-semibold text-xs">Employee</th>
          <th class="text-left whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Current Total Cash</th>
          <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Current Compa</th>
          <th class="text-left whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Benchmark</th>
          <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Suggested % Increase</th>
          <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">% Increase Base</th>
          <th v-if="!nobodyHasVariablePay" class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">% Increase Variable Pay</th>
          <th v-if="!nobodyHasVariablePay" class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Total % Increase</th>
          <th class="text-left whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">New Total Cash</th>
          <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">New Compa</th>
          <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Actions</th>
        </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-200">
          <template v-for="participant in allPossibleParticipants">
            <ineligible-participant-detail :key="participant.eeid" v-if="participant.not_eligible"
                  :participant="participant"
                  :use-local-currency="useLocalCurrency"
                  :adjust-benchmarks="usePerformanceIndicator"
                  :nobody-has-variable-pay="nobodyHasVariablePay"
                  :currency-rate="currencyForParticipant(participant)"
                  />

              <participant-detail-table-version :key="participant.eeid" v-else
                  :participant="participant"
                  :use-local-currency="useLocalCurrency"
                  :adjust-benchmarks="usePerformanceIndicator"
                  :cycle="cycle"
                  :editable="userHasEditPowers || (sheetBelongsToUser && !submitted) || (userNeedsToApprove)"
                  :nobody-has-variable-pay="nobodyHasVariablePay"
                  :currency-rate="currencyForParticipant(participant)"
                  :comments="commentsForParticipant(participant)"
                  :mentionables="mentionables"
                  :participant-submissions="submissionsForParticipant(participant)"
                  @update-participant="updateParticipant"
              />
          </template>

        </tbody>
      </table>
    </div>


    <div class="mt-8 border-t-2 border-gray-100 text-sm mb-8 pt-2">
      If you have any questions on how to use the tool, reach out in #ask-people-team. <br/>
      For feature requests, general comments and suggestions, please add your detailed thoughts to this <a class="no-underline hover:underline text-orange-600" href="https://product-feedback-form-27eb9a.zapier.app/camundipay-feedback">form</a> which will notify the team!
    </div>

    <submit-modal v-if="showSubmitModal"
                  :participants="participants"
                  @close="showSubmitModal = false" @submit="submitSheet"/>

    <approve-modal v-if="showApproveModal"
                   :planner="planner"
                   :participants="participants"
                   @close="showApproveModal = false" @approve="approveSheet"/>

    <reject-modal v-if="showRejectModal"
                  :planner="planner"
                  :participants="participants"
                  @close="showRejectModal = false" @reject="rejectSheet"/>
  </div>
</template>

<script>

import ParticipantDetailTableVersion  from "~/components/compensation_review/ParticipantDetailTableVersion.vue";
import SubmitModal from "~/components/compensation_review/SubmitModal.vue";
import ApproveModal from "~/components/compensation_review/ApproveModal.vue";
import RejectModal from "~/components/compensation_review/RejectModal.vue";
import IneligibleParticipantDetail  from "~/components/compensation_review/IneligibleParticipantDetail.vue";

export default {
  name: "PlannerSheet",
  components: {
    ParticipantDetailTableVersion,
    SubmitModal,
    ApproveModal,
    RejectModal,
    IneligibleParticipantDetail
  },
  props: {
    cycle: Object,
    planner: Object,
    participants: Array,
    currencies: Array,
    benchmarks: Array,
    user: Object,
    approvals: Object,
    comments: Object,
    multiplePlanners: Boolean,
    mentionables: Array,
    participantSubmissions: Array,
    showHeader: {
      type: Boolean,
      default: true,
      optional: true
    },
    ineligibleParticipants: Array
  },
  data() {
    return {
      worksheetCurrency: "Local Currency",
      usePerformanceIndicator: "No",
      submissionHappened: false,
      submission: null,
      showSubmitModal: false,
      showApproveModal: false,
      showRejectModal: false,
      includeIneligibleParticipants: false
    }
  },
  computed: {
    allPossibleParticipants() {
      if (!this.includeIneligibleParticipants) {
        return this.participants
      }
      // combine participants and ineligible participants and sort by name
      return this.participants.concat(this.ineligibleParticipants).sort((a, b) => a.name.localeCompare(b.name))
    },
    totalSpend() {
      let total = 0
      this.participants.forEach(participant => {
        let baseSalaryDifference = Number(participant.new_base_salary_in_cycle_currency) - Number(participant.base_salary_cycle_currency)
        let variablePayDifference = Number(participant.new_variable_pay_in_cycle_currency) - Number(participant.variable_pay_cycle_currency)

        if (baseSalaryDifference < 0) {
         baseSalaryDifference = 0
        }

        if (variablePayDifference < 0) {
          variablePayDifference = 0
        }

        const difference = baseSalaryDifference + variablePayDifference
        total = total + difference
      })
      return total
    },
    exportLink() {
      return `/compensation_cycles/${this.cycle.id}/cycle_planners/${this.planner.id}/download`
    },
    userHasEditPowers() {
      if (this.planner.status == 'approved') {
        return false
      }
      return this.user.roles.includes('admin') || this.user.roles.includes('total_rewards_compensation_cycles') || this.user.roles.includes('coach_compensation_cycles')
    },
    useLocalCurrency() {
      return this.worksheetCurrency == "Local Currency"
    },
    budgetRemaining() {
      return this.planner.budget - this.totalSpend
    },
    inDraft() {
      return (this.planner.status == "requested" || this.planner.status == 'rejected')
    },
    submitted() {
      return this.planner.status == "submitted" || this.planner.status == 'approved'
    },
    sheetBelongsToUser() {
      return this.planner.eeid == this.user.eeid
    },
    currentOpenApproval() {
      return this.approvals.find(approval => approval.status == 'requested')
    },
    userNeedsToApprove() {
      if (this.currentOpenApproval && this.currentOpenApproval.approver_eeid == this.user.eeid && (this.currentOpenApproval.status == 'requested' || this.currentOpenApproval.status == 'rejected') ) {
        return true
      }

      if (this.currentOpenApproval && this.currentOpenApproval.approved_eeid == null && this.currentOpenApproval.name == 'Total Rewards') {
        if (this.cycle.total_rewards_users.includes(this.user.eeid.toString())) {
          return true
        }
      }

      return false
    },
    nobodyHasVariablePay() {
      return this.participants.every(participant => participant.variable_pay == null || participant.variable_pay == 0.0)
    }
  },
  methods: {
    currencyForParticipant(participant) {
      const currency = this.currencies.find(c => {return c.currency == participant.currency})
      if (currency == null) {
        alert('we have an issue')
      }
      return currency.rate
    },
    commentsForParticipant(participant) {
      if (this.comments[participant.id] == null) {
        return []
      }
      return this.comments[participant.id]
    },
    submissionsForParticipant(participant) {
      if (this.participantSubmissions[participant.id] == null) {
        return []
      }

      return this.participantSubmissions[participant.id]
    },
    hidePlanner() {
      this.$emit('hide-planner')
    },
    updateParticipant(participant) {
      this.$emit('update-participant', participant)
    },
    openSubmitModal() {
      this.showSubmitModal = true
    },
    openApproveModal() {
      this.showApproveModal = true
    },
    openRejectModal() {
      this.showRejectModal = true
    },
    async submitSheet() {
      this.showSubmitModal = false
      this.$emit('planner-submitted')
      await fetch(`/compensation_cycles/${this.cycle.id}/cycle_planners/${this.planner.id}/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        }
      })
    },
    async approveSheet() {
      this.showApproveModal = false
      this.submissionHappened = true
      this.submission = 'approved'
      let approval = this.currentOpenApproval
      approval.status = 'approved'
      this.$emit('update-approval', approval)

      const response = await fetch(`/compensation_cycles/${this.cycle.id}/cycle_planner_approvals/${approval.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        },
        body: JSON.stringify({
          cycle_planner_approval: {status: 'approved'}
        })
      })
    },
    async rejectSheet() {
      this.showRejectModal = false
      this.submissionHappened = true
      this.submission = 'rejected'
      let approval = this.currentOpenApproval
      approval.status = 'rejected'
      this.$emit('update-approval', approval)

      await fetch(`/compensation_cycles/${this.cycle.id}/cycle_planner_approvals/${approval.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        },
        body: JSON.stringify({
          cycle_planner_approval: {status: 'rejected'}
        })
      })
    }
  }
}
</script>