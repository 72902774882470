import "trix"
import "@rails/actiontext"

import 'tippy.js/dist/tippy.css'

import VueTippy from 'vue-tippy'

import './application.css'

import { createApp } from 'vue'
import CalculatorApp from '../components/calculators/TotalRewards.vue'
import JobSkillApp from '../components/job_skills_framework/JobSkillView.vue'
import CompensationCycleApp from '../components/compensation_review/CycleOverview.vue'
import TestApp from '../components/calculators/TestTotalRewards.vue'
import CompensationCommittee from '../components/compensation_review/CompensationCommittee.vue'

if (document.querySelector("#calculator-test")) {
    createApp(TestApp).mount('#calculator-test')
}

if (document.querySelector("#calculator")) {
    createApp(CalculatorApp).use(VueTippy).mount('#calculator')
}

if (document.querySelector("#job-skill-view")) {
    createApp(JobSkillApp).mount('#job-skill-view')
}

if (document.querySelector("#compensation-cycle-view")) {
    let el = document.querySelector("#compensation-cycle-view");
    const cycleId = el.dataset.cycleId
    const camundiPay = createApp(CompensationCycleApp, {cycleId: cycleId})
    camundiPay.use(VueTippy)
    camundiPay.mount('#compensation-cycle-view')
}


if (document.querySelector("#cycle-comp-committee-view")) {
    let el = document.querySelector("#cycle-comp-committee-view");
    const cycleId = el.dataset.cycleId
    const camundiPay = createApp(CompensationCommittee, {cycleId: cycleId})
    camundiPay.use(VueTippy)
    camundiPay.mount('#cycle-comp-committee-view')
}



