<template>
  <transition name="modal-fade w-full justify-center mx-auto m-2 overflow-auto">
    <div class="modal-backdrop w-full justify-center mx-auto m-2 overflow-auto">
      <div ref="modal"  class="modal"
           tabindex="0"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           @keydown.esc="close"
      >
        <header
            class="modal-header"
            id="modalTitle"
        >
          <slot name="header">
            <p class="text-blaze-orange-600">
              {{ participant.name}}
            </p>
          </slot>

        </header>

        <section
            class="modal-body"
            id="modalDescription"
        >
          <slot name="body">
            <template v-for="submission in participantSubmissions">
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <p class="text-sm font-bold text-gray-900">{{submission.user_email}}</p>
                  <p class="text-xs text-gray-500 ml-2">{{submission.created_at}}</p>
                </div>
                <p class="text-sm text-gray-900">
                  <span v-if="submission.submission.base_salary_percentage_increase && submission.submission.base_salary_percentage_increase != '0.0'">
                    {{sentence(submission, 'base_salary')}}
                  </span>

                  <span v-else>
                    Received no base salary increase.
                  </span>
                </p>

                <p class="text-sm text-gray-900" v-if="hasVariablePay">
                  <span v-if="submission.submission.variable_pay_percentage_increase && submission.submission.variable_pay_percentage_increase != '0.0'">
                    {{sentence(submission, 'variable')}}
                  </span>

                  <span v-else>
                    Received no variable pay increase.
                  </span>
                </p>
              </div>
              <hr class="my-2" />
            </template>

            <span v-if="participantSubmissions.length == 0" class="text-sm text-gray-900">No submissions yet</span>
          </slot>

        </section>

        <footer class="modal-footer">
          <p class="text-right text-sm">
            <button
                type="button"
                class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                @click="close"
                aria-label="Close"
            >
              Close
            </button>
          </p>

        </footer>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'ParticipantHistory',
  props: {
    participant: {
      type: Object,
      required: true,
    },
    participantSubmissions: {
      type: Array,
      required: true,
    }
  },
  computed: {
    hasVariablePay() {
      // this is true if the participant currently has variable pay or in the future will have variable pay
      return (this.participant.variable_pay && this.participant.variable_pay > 0) || (this.participant.new_variable_pay && this.participant.new_variable_pay > 0)
    }
  },
  mounted() {
    this.$refs.modal.focus();
  },
  methods: {
    verb(submission) {
      if (submission.cycle_planner_approval_id) {
        return 'Approved';
      }
      else if (submission.cycle_planner_id) {
        return 'Submitted';
      } else {
        return 'ERROR';
      }
    },
    sentence(submission, type) {
      if (type == 'base_salary') {
        if (submission.action == 'updated') {
          return `Updated base salary increase to ${submission.submission.base_salary_percentage_increase}%`
        }

        return `${this.verb(submission)} a ${submission.submission.base_salary_percentage_increase}% base salary increase.`
      }

      if (type == 'variable') {
        if (submission.action == 'updated') {
          return `Updated variable pay increase to ${submission.submission.variable_pay_percentage_increase}%`
        }

        return `${this.verb(submission)} a ${submission.submission.variable_pay_percentage_increase}% variable pay increase.`
      }

    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
