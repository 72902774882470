<template>
  <div class="mt-2">
    <div class="">
      <!-- Title-->
      <p class="text-2xl mb-1.5 text-center" v-if="cycle">{{cycle.name}}</p>
      <p class="text-2xl mb-1.5 text-center" v-else>Cycle Overview</p>

      <!-- Cycle information-->

      <div id="vue-loaded" class="mt-3 gap-4 mb-3 flex" v-if="cycle && showPlanners">
        <div class="w-1/3 flex flex-col">
          <div class="flex-1 backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
            <div>Total Budget: €{{new Intl.NumberFormat('en-US').format(Math.round(totalBudget))}}</div>
            <div>Total Budget Spent: €{{new Intl.NumberFormat('en-US').format(Math.round(totalBudgetSpent))}}</div>
            <div>Budget Left:
              <span :class="{'text-red-500': totalBudget - totalBudgetSpent < 0}">
                €{{new Intl.NumberFormat('en-US').format(Math.round(totalBudget - totalBudgetSpent))}}
              </span>
            </div>

            <ul class="text-sm mt-2" v-if="user.roles.includes('admin')">
              <li>Average New Compa Ratio: {{averageNewCompaRatio.toFixed(2)}}</li>
              <li>Average Base Salary Increase: {{averageBaseIncrease.toFixed(2)}}%</li>
              <li>Median Base Salary Increase: {{medianBaseIncrease.toFixed(2)}}%</li>
            </ul>
          </div>
        </div>

        <div class="w-1/3 backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
          <div class="flex-1">
            <cycle-summary
                :planners="planners"
                :participants="Object.values(this.participants).flat()"
                :user="user"
            />
          </div>
        </div>

        <div class="w-1/3 backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color flex flex-col">
          <div>View:
            <select v-model="cycleView" class="border-1 cursor-pointer border-gray-400">
              <option>Planners</option>
              <option>Roll-up</option>
              <option>Sheet View</option>
            </select>
          </div>

          <div class="mt-3 flex" v-if="cycleView=='Planners'">
            <div>Filter:
              <select v-model="plannerView" class="border-1 cursor-pointer border-gray-400">
                <option>All</option>
                <option>Submitted</option>
                <option>Approved</option>
                <option>Action Needed</option>
                <option>With Exceptions</option>
              </select>
            </div>
          </div>

          <div class="relative mt-2 hidden">
            <input type="text"  class="pl-10 pr-4 py-2 border rounded-lg"  placeholder="Filter the planner"  v-model="searchPlanners"/>
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
            </div>
          </div>

          <div class="float-right mt-8" v-if="cycleView == 'Roll-up'">
            <a
                :href="exportLink"
                target="_blank"
                class="text-xs bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
            >
              Export
            </a>
          </div>
        </div>
      </div>


      <!-- Planners information-->
      <div v-if="planners && planners.length > 1 && showPlanners && cycleView == 'Planners'">
        <div class="mt-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
          <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color w-full overflow-x-auto">
            <h2 class="font-semibold text-xl tracking-tight text-color mb-4">Planners</h2>

            <table class="w-full table table-auto">
              <thead>
              <tr>
                <th class="text-left hidden lg:block md:block">Status</th>
                <th class="text-left">Planner</th>
                <th class="hidden lg:block md:block text-left">Participants</th>
                <th class="text-left">Approval Flow</th>
                <th class="text-left">Budget</th>
                <th class="text-center">Action Needed</th>
              </tr>
              </thead>
              <tbody>
                <planner-detail
                    :planner="rootPlanner"
                    :participants="participants[rootPlanner.eeid]"
                    :ineligible-participants="ineligbleParticipants[rootPlanner.eeid] || []"
                    :approvals="approvals[rootPlanner.id]"
                    name-addition=""
                    :user="user"
                    :planner-filter="plannerView"
                    :cycle="cycle"
                    @click="revealPlan(rootPlanner)"
                    @hide-planner="hidePlan"

                  />

                <template v-for="planner in plannersChildren(rootPlanner)">
                  <planner-detail
                    :planner="planner"
                    :participants="participants[planner.eeid]"
                    :ineligible-participants="ineligbleParticipants[planner.eeid] || []"
                    :approvals="approvals[planner.id]"
                    name-addition="&nbsp;&nbsp;&#x2192;"
                    :user="user"
                    :planner-filter="plannerView"
                    :cycle="cycle"
                    @click="revealPlan(planner)"
                    @hide-planner="hidePlan"
                  />

                  <template v-for="childPlanner in plannersChildren(planner)">
                    <planner-detail
                      :planner="childPlanner"
                      :participants="participants[childPlanner.eeid] || []"
                      :ineligible-participants="ineligbleParticipants[childPlanner.eeid] || []"
                      :approvals="approvals[childPlanner.id]"
                      name-addition="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x2192;"
                      :user="user"
                      :planner-filter="plannerView"
                      :cycle="cycle"
                      @click="revealPlan(childPlanner)"
                      @hide-planner="hidePlan"
                    />

                    <template v-for="grandChildPlanner in plannersChildren(childPlanner)">
                      <planner-detail
                          :planner="grandChildPlanner"
                          :participants="participants[grandChildPlanner.eeid] || []"
                          :ineligible-participants="ineligbleParticipants[grandChildPlanner.eeid] || []"
                          :approvals="approvals[grandChildPlanner.id]"
                          name-addition="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x2192;"
                          :user="user"
                          :planner-filter="plannerView"
                          :cycle="cycle"
                          @click="revealPlan(grandChildPlanner)"
                          @hide-planner="hidePlan"
                      />
                    </template>
                  </template>


                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Roll-up information-->
      <roll-up-view v-if="participants && cycleView == 'Roll-up'"
        :participants="participants"
      />

      <!-- Planner sheet-->
      <planner-sheet v-if="cycle && currentPlanner"
        :currencies="currencies"
        :cycle="cycle"
        :planner="currentPlanner"
        :participants="participants[currentPlanner.eeid]"
        :ineligible-participants="ineligbleParticipants[currentPlanner.eeid] || []"
        :benchmarks="benchmarks"
        :user="user"
        :approvals="approvalsForPlanner(currentPlanner)"
        :comments="comments"
        :multiple-planners="planners.length > 1"
        :mentionables="mentionables"
        :participant-submissions="participantSubmissions"
        @planner-submitted="updatePlannerStatus"
        @manager-approved="updateApprovalStatus"
        @hide-planner="hidePlan"
        @update-participant="updateParticipant"
      />

      <planner-sheet v-if="cycleView == 'Sheet View'"
                      :currencies="currencies"
                      :cycle="cycle"
                      :planner="rootPlanner"
                      :participants="Object.values(this.participants).flat().sort((a, b) => a.name.localeCompare(b.name))"
                      :ineligible-participants="Object.values(this.ineligbleParticipants).flat() || []"
                      :benchmarks="benchmarks"
                      :user="user"
                      :approvals="Object.values(approvals).flat()"
                      :comments="comments"
                      :multiple-planners=false
                      :mentionables="mentionables"
                      :participant-submissions="participantSubmissions"
                      :show-header=false
                      @planner-submitted="updatePlannerStatus"
                      @manager-approved="updateApprovalStatus"
                      @hide-planner="hidePlan"
                      @update-participant="updateParticipant"
                      @update-approval="updateApproval"
      />

    </div>
  </div>

</template>

<script>

import PlannerSheet from './PlannerSheet.vue'
import PlannerDetail from './PlannerDetail.vue'
import RollUpView from './RollUpView.vue'
import CycleSummary from "~/components/compensation_review/CycleSummary.vue";

import { formatAmount } from "../shared/utils";
import {root} from "postcss";
export default {
  name: 'CycleOverview',
  components: {
    PlannerSheet,
    PlannerDetail,
    RollUpView,
    CycleSummary
  },
  props: {
    cycleId: String,
  },
  data() {
    return {
      sourceData: null,
      cycle: null,
      participants: [],
      planners: [],
      currencies: [],
      currentPlanner: null,
      showPlanners: true,
      benchmarks: [],
      rootPlanner: null,
      approvals: [],
      useUserAsRoot: true,
      cycleView: 'Planners',
      comments: [],
      searchPlanners: null,
      exportType: 'rollup',
      participantSubmissions: [],
      plannerView: 'All',
      user: null,
      ineligbleParticipants: [],
    }
  },
  computed: {
    averageNewCompaRatio() {
      return Object.values(this.participants).flat().reduce((acc, participant) => acc + Number(participant.new_compa_ratio), 0.0) / Object.values(this.participants).length
    },
    averageBaseIncrease() {
      let participantsWithIncrease = Object.values(this.participants).flat().filter(participant => participant.base_salary_percentage_increase && participant.base_salary_percentage_increase > 0)

      return participantsWithIncrease.reduce((acc, participant) => acc + Number(participant.base_salary_percentage_increase), 0.0) / participantsWithIncrease.length
    },
    medianBaseIncrease() {
      let allIncreases = Object.values(this.participants).flat().map(participant => Number(participant.base_salary_percentage_increase)).filter(increase => increase > 0)
      const mid = Math.floor(allIncreases.length / 2)
      let nums = [...allIncreases].sort((a, b) => a - b);
      return allIncreases.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    },
    exportLink() {
      if (this.exportType == 'all') {
        return `/compensation_cycles/${this.cycle.id}/export`
      }
      return `/compensation_cycles/${this.cycle.id}/cycle_planners/${this.rootPlanner.id}/download_rollup`
    },
    totalBudget() {
      return this.planners.reduce((total, planner) => {
        return total + planner.budget
      }, 0)
    },
    totalBudgetSpent() {
      let total = 0
      Object.values(this.participants).flat().forEach(participant => {
        let baseSalaryDifference = 0
        let variablePayDifference = 0

        if (participant.new_base_salary_in_cycle_currency && participant.new_base_salary_in_cycle_currency != null && participant.new_base_salary_in_cycle_currency != 0) {
          baseSalaryDifference = Number(participant.new_base_salary_in_cycle_currency) - Number(participant.base_salary_cycle_currency)
        }

        if (participant.variable_pay_cycle_currency && participant.variable_pay_cycle_currency != null && participant.new_variable_pay_in_cycle_currency && participant.new_variable_pay_in_cycle_currency != null && participant.new_variable_pay_in_cycle_currency != 0) {
          variablePayDifference = Number(participant.new_variable_pay_in_cycle_currency) - Number(participant.variable_pay_cycle_currency)
        }

        const difference = baseSalaryDifference + variablePayDifference
        total = total + difference
      })
      return total
    },
  },
  methods: {
    root,
    formatAmount,
    setData(data) {
      this.sourceData = data
      this.cycle = data.compensation_cycle
      this.participants = data.participants
      this.planners = data.planners
      this.currencies = data.currencies
      this.benchmarks = data.benchmarks
      this.approvals = data.approvals
      this.user = data.current_user
      this.useUserAsRoot = data.use_user_as_root
      this.comments = data.comments
      this.mentionables = data.mentionables
      this.exportType = data.export_type
      this.participantSubmissions = data.participant_submissions
      this.ineligbleParticipants = data.ineligible_participants

      if (this.planners.length == 1) {
        this.currentPlanner = this.planners[0]
        this.showPlanners = false
      }
      else if (this.useUserAsRoot) {
        this.rootPlanner = this.planners.find(planner => planner.eeid == this.user.eeid)
      }
      else if (this.sourceData.root_planner != null) {
        this.rootPlanner = this.planners.find(planner => planner.eeid == this.sourceData.root_planner)
      }
      else {
        this.rootPlanner = this.planners.find(planner => planner.eeid == this.cycle.root_planner)
      }

      this.$nextTick(() => {
        this.setParamsFromUrl(window.location.href);
      })

    },
    setParamsFromUrl(url) {
      const currentParams = (new URL(url)).searchParams
      const plannerParam = currentParams.get('planner')

      if (plannerParam) {
        this.currentPlanner = this.planners.find(planner => planner.id == plannerParam)
        this.showPlanners = false
      }
    },
    async getSourceData() {
      await fetch('/compensation_cycles/data.json?id=' + this.cycleId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'same-origin'
      })
      .then(response => response.json())
      .then(data => {
        this.setData(data)
      })
    },
    approvalsForPlanner(planner) {
      if (this.approvals == null || this.approvals[planner.id] == null) {
        return []
      }
      return this.approvals[planner.id].sort ((a, b) => {
        return a.level - b.level
      })
    },
    revealPlan(planner) {
      this.showPlanners = false
      this.currentPlanner = planner
    },
    hidePlan() {
      this.getSourceData()
      this.showPlanners = true
      this.currentPlanner = null
    },
    plannersChildren(planner) {
      return this.planners.filter(p => p.reports_to_eeid == planner.eeid)
    },
    updatePlannerStatus() {
      this.currentPlanner.status = 'submitted'
    },
    updateParticipant(participant) {
      const participantIndex = this.participants[participant.reports_to_eeid].findIndex(p => p.id == participant.id)
      this.participants[participant.reports_to_eeid][participantIndex] = participant
    },
    updateApprovalStatus(approval) {
      const approvalIndex = this.approvals[approval.planner_id].findIndex(a => a.id == approval.id)
      this.approvals[approval.planner_id][approvalIndex] = approval
    },
  },
  mounted () {
    this.getSourceData();
  },
  watch: {

  }
}
</script>