<template>

  <vue-table-lite
      :is-loading="loading"
      :columns="columns"
      :rows="rows"
      :total="rows.length"
      :sortable="sortOptions"
      @is-finished="loading = false"
  />

</template>

<script>
import VueTableLite from "vue3-table-lite";
export default {
  name: 'RollUpView',
  components: {
    VueTableLite
  },
  props: {
    participants: Object,
  },
  data() {
    return {
      sortOptions: {order: "name", sort: "asc"},
      loading: true,
      columns: [
        {
          label: "Name",
          field: "name",
          sortable: true,
        },
        {
          label: "Department",
          field: "department",
          sortable: true,
        },
        {
          label: "Division",
          field: "division",
          sortable: true,
        },
        {
          label: "Start Date",
          field: "start_date",
          sortable: true,
        },
        {
          label: "Job Title",
          field: "job_title",
          sortable: true,
        },
        {
          label: "Performance Indicator",
          field: "performance_indicator",
          sortable: true,
        },
        {
          label: "Currency",
          field: "currency",
          sortable: true,
        },
        {
          label: "Base Salary",
          field: "base_salary",
          sortable: true,
        },
        {
          label: "Variable Pay",
          field: "variable_pay",
          sortable: true,
        },
        {
          label: "Total Cash",
          field: "total_cash",
          sortable: true,
        },
        {
          label: "Compa Ratio",
          field: "compa_ratio",
          sortable: true
        },
        {
          label: "Suggested % Increase",
          field: "total_rewards_recommendation",
          sortable: false
        },
        {
          label: "Base Salary % Increase",
          field: "base_salary_percentage_increase",
          sortable: false
        },
        {
          label: "Variable Pay % Increase",
          field: "variable_pay_percentage_increase",
          sortable: false
        },
        {
          label: "New Base Salary",
          field: "new_base_salary",
          sortable: false
        },
        {
          label: "New Variable Pay",
          field: "new_variable_pay",
          sortable: false
        },
        {
          label: "New Base Salary EUR",
          field: "new_base_salary_in_cycle_currency",
          sortable: false
        },
        {
          label: "New Variable Pay EUR",
          field: "new_variable_pay_in_cycle_currency",
          sortable: false
        }
      ],
      rows: []
    }
  },
  mounted() {
    let flattenedParticipants = []
    Object.values(this.participants).flat().forEach(participant => {
      flattenedParticipants.push({
        currency: participant.currency,
        name: participant.name,
        department: participant.department,
        division: participant.division,
        start_date: participant.start_date,
        job_title: participant.job_title,
        performance_indicator: participant.performance_indicator,
        base_salary: new Intl.NumberFormat('en-US').format(Math.round(participant.base_salary)),
        variable_pay: new Intl.NumberFormat('en-US').format(Math.round(participant.variable_pay)),
        total_cash: new Intl.NumberFormat('en-US').format(Math.round(Number(participant.base_salary) + Number(participant.variable_pay))),
        compa_ratio: Math.round(participant.compa_ratio * 100) / 100,
        total_rewards_recommendation: `${participant.min_recommendation}% - ${participant.max_recommendation}%`,
        base_salary_percentage_increase: participant.base_salary_percentage_increase || 0.0,
        variable_pay_percentage_increase: participant.variable_pay_percentage_increase || 0.0,
        new_base_salary: new Intl.NumberFormat('en-US').format(Math.round(participant.new_base_salary)),
        new_variable_pay: new Intl.NumberFormat('en-US').format(Math.round(participant.new_variable_pay)),
        new_base_salary_in_cycle_currency: new Intl.NumberFormat('en-US').format(Math.round(participant.new_base_salary_in_cycle_currency)),
        new_variable_pay_in_cycle_currency: new Intl.NumberFormat('en-US').format(Math.round(participant.new_variable_pay_in_cycle_currency))
      })
    })
    this.rows = flattenedParticipants
    this.loading = false
  },
}
</script>