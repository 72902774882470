<template>
  <div>
    <div class="mt-2" >
      <p class="text-2xl mb-1.5 text-center">Job Catalog</p>

      <p  v-if="jobRoles.length !== 0">
        Please select the role and level:
      </p>

      <div class="flex flex-wrap -mx-1" v-if="jobRoles.length !== 0">
        <div class="my-1 px-1 w-1/2 pr-2">
          <Multiselect
              v-model="currentRole"
              :options="jobRoles"
              :searchable=true
              label="name"
              placeholder="Select a role"
              valueProp="name"
          />
        </div>

        <div class="my-1 px-1 w-1/2" v-if="currentRole">
          <Multiselect
              v-model="currentLevel"
              :options="currentRoleLevels"
              :searchable=true
              label="name"
              placeholder="Select a level"
              valueProp="name"
          />
        </div>
      </div>


      <div class="mt-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4" v-if="currentRoleDefinition">
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
          <h2 class="font-semibold text-xl tracking-tight text-color mb-4">Job Summary</h2>
          <div v-if="currentRoleDefinition.description" class="font-sm text-color mb-4 styled trix-content" v-html="currentRoleDefinition.description"></div>
          <div v-else>There is no role summary yet.</div>
          <p class="float-right text-sm text-gray-500 cursor-pointer" @click="showJobDetails">Job Details</p>
        </div>
      </div>

      <div class="mt-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4" v-if="currentRoleDefinition && currentLevelIsManagerial">
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
          <h2 class="font-semibold text-xl tracking-tight text-color  mb-4">Core Skills</h2>
          <div class="font-sm text-color mb-4">
            <div v-for="skill in roleCoreSkills">
              <div class="flex justify-between">
                <div class="cursor-pointer hover:text-orange-600" @click="showCoreSkill(skill)">{{skill.name}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color" v-if="currentLevelIsManagerial">
          <h2 class="font-semibold text-xl tracking-tight text-color  mb-4">Manager Skills</h2>
          <div class="font-sm text-color mb-4">
            <div v-for="skill in managerSkills">
              <div class="flex justify-between">
                <div class="cursor-pointer hover:text-orange-600" @click="showProgression(skill.name, true)">{{skill.name}}</div>
              </div>
            </div>
          </div>
        </div>


        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
            <h2 class="font-semibold text-xl tracking-tight text-color  mb-4">Job Specific Skills</h2>
            <div v-if="currentSkillDefinition" class="font-sm text-color mb-4">
              <div v-for="skill in currentSkillDefinition.skill_levels">
                <div class="flex justify-between">
                  <div class="cursor-pointer hover:text-orange-600" @click="showProgression(skill.skill, false)">{{skill.skill}}</div>
                </div>
              </div>
          </div>
          <div v-else>There are no skills assigned to this job role yet.</div>
        </div>
      </div>

      <div class="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4" v-if="currentRoleDefinition && !currentLevelIsManagerial">
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
          <h2 class="font-semibold text-xl tracking-tight text-color  mb-4">Core Skills</h2>
          <div class="font-sm text-color mb-4">
            <div v-for="skill in roleCoreSkills">
              <div class="flex justify-between">
                <div class="cursor-pointer hover:text-orange-600" @click="showCoreSkill(skill)">{{skill.name}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color">
          <h2 class="font-semibold text-xl tracking-tight text-color  mb-4">Job Specific Skills</h2>
          <div v-if="currentSkillDefinition" class="font-sm text-color mb-4">
            <div v-for="skill in currentSkillDefinition.skill_levels">
              <div class="flex justify-between">
                <div class="cursor-pointer hover:text-orange-600" @click="showProgression(skill.skill, false)">{{skill.skill}}</div>
              </div>
            </div>
          </div>
          <div v-else-if="loadingData">We are loading the skills for this role.</div>
          <div v-else>There are no skills assigned to this job role yet.</div>
        </div>
      </div>

      <div class="text-left ml-2 mt-1" v-if="coreSkills && currentLevel">
        <p class="text-sm text-gray-500">Click on the skill name to see more details.</p>
      </div>

      <div class="float-right" v-if="shareLink">
        <p @click="copyToClipboard" class="cursor-pointer text-sm hover:text-orange-600">Copy URL to this page</p>
        <p><a :href="calculatorLink" class="cursor-pointer text-sm hover:text-orange-600">Total Rewards Calculator</a></p>
        <a
            :href="exportLink"
            target="_blank"
            class="cursor-pointer text-sm hover:text-orange-600"
        >
          Export Skills for Job Family
        </a>
      </div>

      <div class="mt-5" v-if="currentRoleDefinition && showSkillProgression">
        <Progression
            :skill="progressionSkillDefinition"
            :current-level="currentRoleDefinition.level"
            :manager-skill="progressionForManagerSkill"
            :role-levels="mappedRoleLevels"
        />
      </div>

      <div class="mt-5" v-if="currentCoreSkill">
        <h2 class="font-semibold text-xl tracking-tight text-color mt-4 mb-4">{{currentCoreSkill.name}}</h2>
        {{currentCoreSkill.description}}

        <div class="mt-2" v-if="currentCoreSkill.content">
          <h2 class="font-semibold text-l tracking-tight text-color mt-4 mb-4">Behavioural Examples</h2>
          <div class="mt-2 styled trix-content" v-html="currentCoreSkill.content">
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal">
      <JobModal
          :role-details="currentRoleDefinition"
          :job-family="currentJobFamilyDefinition.name"
          @close="closeModal"
      />
    </div>
  </div>
</template>

<script>

import  Multiselect from '@vueform/multiselect'
import Progression from './SkillProgression.vue'
import JobModal from './JobDetailsView.vue'

export default {
  name: "JobSkillView",
  components: {
    Multiselect,
    Progression,
    JobModal
  },
  data() {
    return {
      sourceData: {},
      jobRoles: [],
      currentRole: null,
      currentLevel: null,
      roleSkills: [],
      skills: [],
      progressionSkill: null,
      showSkillProgression: false,
      coreSkills: [],
      showCoreSkillDetails: false,
      currentCoreSkill: null,
      managerSkills: [],
      loadingData: true,
      showModal: false,
      progressionForManagerSkill: false
    }
  },
  mounted () {
    this.getSourceData();
    this.getFullData();
  },
  computed: {
   roleCoreSkills() {
     if (this.currentLevelIsManagerial) {
       return this.coreSkills.filter (skill => {
         return skill.people_manager
       })
     }

     else {
       return this.coreSkills.filter (skill => {
         return !skill.people_manager
       })
     }
   },
    currentJobFamilyDefinition() {
      if (this.currentRole === null || this.currentRole === "" ) {
        return null
      }

      return this.jobRoles.find(jobRole => {
        return jobRole.name === this.currentRole
      })
    },
    currentRoleLevels() {
      if (this.currentJobFamilyDefinition === null) {
        return null
      }
      return this.currentJobFamilyDefinition.levels.sort((level_one, level_two) => level_one.grade < level_two.grade ? -1 : 1)
    },
    currentRoleDefinition() {
      if (!this.currentJobFamilyDefinition || !this.currentLevel) {
        return null
      }

      return this.currentRoleLevels.find(roleLevel => {
        return roleLevel.name == this.currentLevel
      })
    },
    currentSkillDefinition() {
      if (!this.currentRoleLevels) {
        return null
      }

      return this.roleSkills.find(skill => {
        return skill.job_role == this.currentLevel
      })
    },
    shareLink() {
      if (!this.currentRole || !this.currentLevel) {
        return null
      }

      const url = new URL(window.location.href);

      url.searchParams.set("role", this.currentRole)
      url.searchParams.set("level", this.currentLevel)

      return url.toString();
    },
    exportLink() {
      if (!this.currentRole || !this.currentLevel) {
        return null
      }

      const url = new URL(window.location.href);
      url.pathname = "job_skills/export"

      url.searchParams.set("job_role_id", this.currentRoleDefinition.internal_id)

      return url.toString();
    },
    calculatorLink() {
      if (!this.currentRole || !this.currentLevel) {
        return null
      }

      const url = new URL(window.location.href);
      url.pathname = "total_rewards"

      url.searchParams.set("role", this.currentRole)
      url.searchParams.set("level", this.currentLevel)

      return url.toString();
    },
    progressionSkillDefinition() {
      if (!this.progressionSkill) {
        return null
      }

      let skillDefinition = this.skills.find(skill => {
        return skill.name == this.progressionSkill
      })

      if (!skillDefinition) {
        skillDefinition = this.managerSkills.find(skill => {
          return skill.name == this.progressionSkill
        })
      }

      return skillDefinition
    },
    currentLevelIsManagerial() {
      if (!this.currentRoleDefinition) {
        return false
      }

      if (this.currentRoleDefinition.level == "Manager" || this.currentRoleDefinition.level == "Senior Manager" || this.currentRoleDefinition.level == "Director" || this.currentRoleDefinition.level == "Senior Director" || this.currentRoleDefinition.level == "Vice President") {
        return true;
      }
      return false
    },
    mappedRoleLevels() {
      if (this.currentRoleLevels) {
        return this.currentRoleLevels.map(level => {
          return level.level
        })
      }
      else {
        return []
      }
    }
  },
  methods: {
    showProgression(skill, forManagerSkill = false) {
      this.progressionSkill = skill
      this.showSkillProgression = true
      this.showCoreSkillDetails = false
      this.currentCoreSkill = null
      this.progressionForManagerSkill = forManagerSkill
    },
    showCoreSkill(coreSkill) {
      this.currentCoreSkill = coreSkill
      this.showCoreSkillDetails = true
      this.showSkillProgression = false
    },
    showJobDetails() {
      this.showModal = true
    },
    setSourceData(data) {
      this.sourceData = data
      this.jobRoles = data.job_roles
      this.coreSkills = data.core_skills

      this.$nextTick(() => {
        this.setParamsFromUrl(window.location.href);
      })

      this.$nextTick(() => {
        if (this.jobRoles.length == 1) {
          this.currentRole = this.jobRoles[0].job_role
        }
      })
    },
    setData(data) {
      this.sourceData = data
      // this.jobRoles = data.job_roles
      this.skills = data.skills
      this.roleSkills = data.role_skills
      this.managerSkills = data.manager_skills
    },
    setCurrentRoleLevel() {
      if (this.currentRoleLevels.length > 0) {
        this.currentLevel = this.currentRoleLevels[0].job_title
      }
      else {
        // this.currentLevel = null
      }
    },
    async getSourceData() {
      await fetch('/job_skills/data.json?full_load=false', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'same-origin'
      })
          .then(response => response.json())
          .then(data => {
            this.setSourceData(data)
          })
    },
    async getFullData() {
      await fetch('/job_skills/data.json?full_load=true', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'same-origin'
      })
          .then(response => response.json())
          .then(data => {
            this.setData(data)
            this.loadingData = true
          })
    },
    setParamsFromUrl(url){
      const currentParams = (new URL(url)).searchParams;
      const roleParam = currentParams.get('role')
      const levelParam = currentParams.get('level')

      this.currentRole = roleParam
      this.currentLevel = levelParam
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.shareLink);
    },
    closeModal() {
      this.showModal = false
    }
  },
  watch: {
    currentRole(newRole, oldRole) {
      if (newRole == null) {
        this.currentCoreSkill = null
      }
      if (newRole && newRole != oldRole && oldRole !== null) {
        this.setCurrentRoleLevel()
        this.showSkillProgression = false
        this.progressionSkill = false
        this.currentCoreSkill = null
      }
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

