<template>
  <div>
    <h2 class="font-semibold text-xl tracking-tight text-color mt-4 mb-4">
      {{skill.name}}
      <span v-if="existingDifference > 0 && !showAll" class='text-sm font-normal hover:text-blaze-orange-600 cursor-pointer' @click="toggleLevels">(Toggle all levels)</span>
      <span v-if="existingDifference > 0 && showAll" class='text-sm font-normal hover:text-blaze-orange-600 cursor-pointer' @click="toggleLevels">(Only show levels within the job role)</span>
    </h2>
    <ol
        class="border-l border-neutral-300 dark:border-neutral-500 md:flex md:justify-center md:gap-6 md:border-l-0 md:border-t mt-5">
      <!--First item-->
      <li v-for="skilllevel in shownLevels">

        <div class="flex-start flex items-center pt-2 md:block md:pt-0">
          <div v-if="currentLevel == skilllevel.level" class="ml-[-5px] mr-3 h-[9px] w-[9px] rounded-full bg-orange-500 md:-mt-[5px] md:ml-0 md:mr-0"></div>
          <div v-else class="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-neutral-300 dark:bg-neutral-500 md:-mt-[5px] md:ml-0 md:mr-0"></div>
        </div>
        <div class="ml-4 mt-2 pb-5 md:ml-0">
          <h4 class="mb-1.5 text-xl font-semibold">{{skilllevel.level}}</h4>
          <p class="mb-3 text-neutral-500 dark:text-neutral-300 text-sm trix-content" v-html="skilllevel.description"></p>
          <p v-if="managerSkill" class="font-semibold text-md tracking-tight text-color mt-4 mb-4">Behavioural Examples</p>
          <p class="m-2  styled trix-content" v-if="managerSkill" v-html="skilllevel.content"></p>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "SkillProgression",
  props: {
    skill: {
      type: Object,
      required: true
    },
    currentLevel: {
      type: String,
      required: false
    },
    managerSkill: {
      type: Boolean,
      required: false
    },
    roleLevels: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showAll: false
    }
  },
  computed: {
    availableLevelsForSkill() {
      if (this.managerSkill) {
        return this.skill.levels
      }

      if (this.currentLevelIsManagerial) {
        return this.skill.levels.filter((skilllevel) => skilllevel.level == "Manager" || skilllevel.level == "Senior Manager" || skilllevel.level == "Director" || skilllevel.level == "Senior Director" || skilllevel.level == "Vice President")
      }
      else {
        return this.skill.levels.filter((skilllevel) => skilllevel.level != "Manager" && skilllevel.level != "Senior Manager" && skilllevel.level != "Director" && skilllevel.level != "Senior Director" && skilllevel.level != "Vice President")
      }
    },
    availableLevelsForRole() {
      if (this.managerSkill) {
        return this.skill.levels
      }

      // use the roleLevels, so that we can show only the levels that are relevant to the role
      return this.skill.levels.filter((skilllevel) => this.roleLevels.includes(skilllevel.level))
    },
    shownLevels() {
      if (this.showAll) {
        return this.availableLevelsForSkill
      }
      return this.availableLevelsForRole
    },
    existingDifference() {
      return this.availableLevelsForSkill.length - this.availableLevelsForRole.length
    },
  },
  methods: {
    toggleLevels() {
      this.showAll = !this.showAll
    }
  }
}
</script>